<template>
  <div class="favq clearfix">
       <headtopsystemgl></headtopsystemgl>
    <div class="enterprise clearfix">
      <div class="left">
        <systemglleft></systemglleft>
      </div>
      <div class="right">
        <systemglyonghu></systemglyonghu>
      </div>
    </div>
  </div>
</template>

<script>
import {} from "@/api/index";
import systemglyonghu from "./systemglyonghu";

export default {
  data() {
    return {
      tagnum: "7"
    };
  },
  components: { systemglyonghu }
};
</script>

<style lang="less" scoped>
.favq {
  width: 100%;
  min-height: 100%;
  background: rgb(243, 243, 243);
  padding-bottom: 30px;

  li {
    list-style: none;
  }

  .c-top {
    width: 1920px;
    height: 72px;
    background: #fff;

    li {
      display: block;
      width: 100px;
      height: 38px;
      background: #eee;
      margin-top: 19px;
      border-radius: 6px;
      margin-right: 19px;
      font-size: 17px;
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #666666;
      text-align: center;
      line-height: 38px;
      float: left;
      &:nth-child(1) {
        margin-left: 52px;
      }
      &.active {
        background: rgb(254, 147, 34);
        color: #ffffff;
      }
    }
  }
}
.enterprise {
  padding: 0 30px;
}
.left {
  float: left;
  background: #fff;
  min-width: 170px;

  .dxhj {
    padding-top: 13px;
    width: 100%;
    height: 38px;

    img {
      float: left;
      width: 38px;
      height: 38px;
      margin-left: 13px;
    }

    span {
      float: left;
      line-height: 31px;
      height: 18px;
      font-size: 18px;
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #222222;
    }
  }

  /deep/ .el-collapse-item__header {
    height: 40px;
    padding-left: 40px;
    font-size: 14px;
  }

  /deep/ .el-collapse-item__header.is-active {
    color: #fe6a00;
  }
}
.seach {
  display: inline-block;
  width: 54px;
  height: 30px;
  background: #fe6a00;
  border-radius: 4px;
  line-height: 30px;
  font-size: 14px;
  text-align: center;
  font-weight: bold;
  color: #ffffff;
  margin: 1% 0;
  cursor: pointer;
}
.right {
  background: #fff;
  width: calc(100% - 256px - 15px);
  min-height: 89vh;
  float: right;
  margin-left: 10px;

  .soso_box {
    padding: 22px 10px 10px 22px;
    margin-bottom: 15px;
    overflow: hidden;
    zoom: 1;

    .zhaosh_tit {
      font-size: 18px;
      // font-weight: bold;
      text-align: left;
    }

    .ipt {
      overflow: hidden;
      zoom: 1;
      text-align: left;
      li {
        display: block;
        width: 16.5%;
        height: 31px;
        float: left;
        font-size: 14px;
        font-weight: 400;
        text-align: left;
        color: #333333;
        margin: 1% 0;

        /deep/.el-select {
          width: 70%;
          height: 30px;
          input {
            width: 100%;
            height: 30px;
            background: #ffffff;
            border: 1px solid #dddddd;
          }
          /deep/.el-input__icon {
            line-height: 30px !important;
          }
        }
        /deep/.el-input {
          width: 100%;
          height: 30px;
          input {
            width: 100%;
            height: 30px;
            color: #333;
            background: #ffffff;
            border: 1px solid #dddddd;
          }
          ::-webkit-input-placeholder {
            color: #333;
          }
          /deep/.el-icon-circle-close {
            background: url("../../assets/merchantsradar/cuo.png") 50% 50%
              no-repeat;
          }
          .el-icon-circle-close:before {
            content: "";
          }
        }
      }

      li:nth-child(2) {
        width: 17.5%;
        /deep/.el-select {
          width: 60%;
        }
      }
      li:nth-child(3) {
        width: 17.5%;
        /deep/.el-select {
          width: 55%;
        }
      }
      li:nth-child(4) {
        width: 26%;
        /deep/.el-select {
          width: 70%;
        }
      }
      li:nth-child(5) {
        width: 17%;
        /deep/.el-input {
          width: 70%;
        }
      }
    }
  }

  .project_main {
    padding: 18px 10px 10px 22px;
    min-height: 80%;
    overflow: hidden;
    zoom: 1;

    .project_tit {
      overflow: hidden;
      zoom: 1;
      margin-bottom: 10px;

      dt {
        float: left;
        font-weight: bold;
        font-size: 16px;
        color: #fe6a00;
        height: 30px;
        line-height: 30px;
      }

      dt:before {
        content: " ";
        padding: 0 3px 0 0;
        margin-right: 6px;
        background: #fe6a00;
      }

      dd {
        float: right;

        .el-checkbox {
          margin-right: 15px;
        }

        .xzeng_btn {
          display: inline-block;
          height: 30px;
          line-height: 30px;
          background: #fe6a00;
          border-radius: 4px;
          font-size: 14px;
          text-align: center;
          color: #ffffff;
          margin: 1% 5px;
          padding: 0 20px;
          cursor: pointer;
        }

        .del_btn {
          display: inline-block;
          height: 30px;
          line-height: 30px;
          border: 1px solid #fe6a00;
          border-radius: 4px;
          font-size: 14px;
          text-align: center;
          color: #fe6a00;
          margin: 1% 5px;
          padding: 0 20px;
          cursor: pointer;
        }

        .xiaz_btn {
          display: inline-block;
          height: 30px;
          line-height: 30px;
          border: 1px solid #fe6a00;
          border-radius: 4px;
          font-size: 14px;
          text-align: center;
          color: #fe6a00;
          margin: 1% 5px;
          padding: 0 20px;
          cursor: pointer;
        }
      }
    }

    .project_tables {
      font-size: 14px;
      line-height: 44px;

      tr:nth-child(odd) {
        background: #f3f6fb;

        td {
          border-top: 1px solid #eee;
          border-bottom: 1px solid #eee;
        }
      }
      span {
        margin: 0 2%;
      }
      .chengzi_btn {
        color: #fe6a00;
        cursor: pointer;
      }
      .lvzi_btn {
        color: #2cb54c;
        cursor: pointer;
      }
      .zizi_btn {
        color: #135de6;
        cursor: pointer;
      }
      .redzi_btn {
        color: #e51313;
        cursor: pointer;
      }
      .bluezi_btn {
        color: #0ebbf3;
        cursor: pointer;
      }
      .huangzi_btn {
        color: #e69554;
        cursor: pointer;
      }

      /deep/.el-button {
        background: none;
        padding: 0;
        border: 0;
      }
    }

    //分页
    /deep/.el-pagination {
      float: right;
      margin-top: 20px;
    }
    /deep/.btn-prev {
      border: 1px solid #888;
      padding: 0;
    }
    /deep/.btn-next {
      border: 1px solid #888;
      padding: 0;
    }
    /deep/.el-pager li {
      border: 1px solid #888;
      margin: 0 5px;
    }
    /deep/.el-pager li.active,
    /deep/.el-pager li:hover {
      border: 1px solid #fe6a00;
      color: #fe6a00;
    }

    //分页end
  }
}
.dispatch {
  width: 100%;
  height: 138px;
  background: #ffffff;
  border: 1px solid #eeeeee;
  z-index: 99;
  margin-top: 15px;
  ul {
    width: 100%;
    height: 138px;
    padding: 10px;
    overflow: auto;
    li {
      list-style: none;
      cursor: pointer;
      height: 30px;
      line-height: 20px;
      width: 100%;
      padding: 5px;
      &:hover {
        background-color: #eee;
        color: #e69554;
      }
      &.active {
        color: #e69554;
        background-color: #eee;
      }
    }
  }
}
.btn {
  text-align: center;
  margin-top: 10px;
  #but1 {
    width: 72px;
    height: 30px;
    background: #fe6a00;
    border-radius: 6px;
    font-size: 14px;
    font-weight: 400;
    color: #ffffff;
    line-height: 30px;
    padding: 0;
  }
  #but2 {
    width: 72px;
    height: 30px;
    background: #ffffff;
    border: 1px solid #fe6a00;
    border-radius: 6px;
    font-size: 14px;
    font-weight: 400;
    color: #fe6a00;
    line-height: 30px;
    padding: 0;
  }
}
.branch {
  overflow: auto;
  ul {
    height: auto;
  }
}
.el-collapse-item {
  padding: 0 10px;
}
.project_tables /deep/ .el-checkbox__label {
  display: none;
}
</style>
