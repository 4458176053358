<template>
  <div>
    <div class="soso_box">
      <div class="zhaosh_tit">
        <span @click="kai(1)" :class="kaiguan ? 'cheng' : ''"
          >局内用户管理</span
        >
        <span @click="kai(2)" :class="!kaiguan ? 'cheng' : ''"
          >外部访客管理</span
        >
        <div class="sousuo" v-if="kaiguan">
          <li>
            <el-input
              v-model="keyword"
              placeholder="搜索用户名或姓名"
            ></el-input>
          </li>
          <span class="seach" @click="sousuo(1)">搜索</span>
        </div>
        <div class="sousuo" v-else>
          <li>
            <el-input v-model="keyword" placeholder="搜索用户名"></el-input>
          </li>
          <span class="seach" @click="chaxun">搜索</span>
        </div>
      </div>

      <!--局内用户管理-->
      <template v-if="kaiguan">
        <ul class="ipt">
          <li>
            状态：
            <el-select
              class="iipp"
              v-model="valuetype"
              clearable
              placeholder="请选择"
              @change="labelchange($event, industry, 1)"
            >
              <el-option
                v-for="(item, index) in industry"
                :key="index"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </li>
          <li>
            所在机构：
            <el-select
              class="iipp"
              v-model="valueaptitude"
              clearable
              placeholder="请选择"
              @change="labelchange($event, goverment, 2)"
            >
              <el-option
                v-for="(item, index) in goverment"
                :key="index"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </li>
          <li>
            所属部门：
            <el-select
              class="iipp"
              v-model="valueaptitude2"
              clearable
              placeholder="请选择"
              @change="labelchange($event, invest, 3)"
            >
              <el-option
                v-for="(item, index) in invest"
                :key="index"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </li>
        </ul>
        <div class="project_main">
          <dl class="project_tit">
            <dd>
              <span class="xzeng_btn" @click="newuser()">+新增</span>
              <!--弹窗-->
              <template v-if="showpup2">
                <div class="dabox">
                  <div class="neibox">
                    <p>新增用户</p>
                    <el-form
                      :model="numberValidateForm"
                      ref="numberValidateForm"
                      label-width="100px"
                      class="demo-ruleForm"
                    >
                      <el-form-item
                        label="姓名 :"
                        prop="real_name"
                        :rules="[{ required: true, message: '姓名不能为空' }]"
                      >
                        <el-input
                          type="real_name"
                          v-model="numberValidateForm.real_name"
                          autocomplete="off"
                        ></el-input>
                      </el-form-item>
                      <el-form-item
                        label="职务 :"
                        prop="position"
                        :rules="[{ required: true, message: '职务不能为空' }]"
                      >
                        <el-input
                          type="position"
                          v-model="numberValidateForm.position"
                          autocomplete="off"
                        ></el-input>
                      </el-form-item>
                      <el-form-item
                        label="职级 :"
                        prop="level"
                        :rules="[{ required: true, message: '职级不能为空' }]"
                      >
                        <el-select
                          v-model="numberValidateForm.level"
                          placeholder="请选择"
                          @change="labelchange($event, listzj, 4)"
                        >
                          <el-option
                            v-for="(item, index) in listzj"
                            :key="index"
                            :label="item.label"
                            :value="item.value"
                          ></el-option>
                        </el-select>
                      </el-form-item>
                      <el-form-item
                        label="所在机构 :"
                        prop="unit"
                        :rules="[
                          { required: true, message: '所在机构不能为空' },
                        ]"
                      >
                        <el-select
                          v-model="numberValidateForm.unit"
                          placeholder="请选择"
                          @change="labelchange($event, goverment, 2)"
                        >
                          <el-option
                            v-for="(item, index) in goverment"
                            :key="index"
                            :label="item.label"
                            :value="item.value"
                          ></el-option>
                        </el-select>
                      </el-form-item>
                      <el-form-item label="所在部门 :" prop="section">
                        <el-select
                          v-model="numberValidateForm.section"
                          placeholder="请选择"
                          @change="labelchange($event, invest, 3)"
                        >
                          <el-option
                            v-for="(item, index) in invest"
                            :key="index"
                            :label="item.label"
                            :value="item.value"
                          ></el-option>
                        </el-select>
                      </el-form-item>
                      <el-form-item
                        label="直系领导 :"
                        prop="leader"
                        :rules="[
                          { required: true, message: '直系领导不能为空' },
                        ]"
                      >
                        <el-input
                          type="name"
                          v-model="numberValidateForm.leader"
                          autocomplete="off"
                        ></el-input>
                      </el-form-item>
                      <el-form-item
                        label="联系电话 :"
                        prop="mobile"
                        :rules="[
                          { required: true, message: '联系电话不能为空' },
                          { type: 'number', message: '联系电话必须为数字值' },
                        ]"
                      >
                        <el-input
                          type="mobile"
                          v-model.number="numberValidateForm.mobile"
                          autocomplete="off"
                        ></el-input>
                      </el-form-item>
                      <el-form-item
                        label="性别 :"
                        prop="sex"
                        :rules="[{ required: true, message: '性别不能为空' }]"
                      >
                        <el-select
                          v-model="numberValidateForm.sex"
                          placeholder="请选择"
                        >
                          <el-option label="男" value="1"></el-option>
                          <el-option label="女" value="2"></el-option>
                        </el-select>
                      </el-form-item>
                      <el-form-item
                        label="年龄 :"
                        prop="age"
                        :rules="[
                          { required: true, message: '年龄不能为空' },
                          { type: 'number', message: '年龄必须为数字值' },
                        ]"
                      >
                        <el-input
                          type="age"
                          v-model.number="numberValidateForm.age"
                          autocomplete="off"
                        ></el-input>
                      </el-form-item>
                      <el-form-item label="邮箱 :" prop="email">
                        <el-input
                          type="email"
                          v-model="numberValidateForm.email"
                          autocomplete="off"
                        ></el-input>
                      </el-form-item>
                      <el-form-item
                        label="用户名 :"
                        prop="name"
                        :rules="[{ required: true, message: '用户名不能为空' }]"
                      >
                        <el-input
                          type="name"
                          v-model="numberValidateForm.name"
                          autocomplete="off"
                        ></el-input>
                      </el-form-item>
                      <el-form-item
                        label="密码 :"
                        prop="password"
                        :rules="[{ required: true, message: '密码不能为空' }]"
                      >
                        <el-input
                          type="password"
                          v-model="numberValidateForm.password"
                          autocomplete="off"
                        ></el-input>
                      </el-form-item>

                      <div class="btn">
                        <el-button @click="addUser" type="primary"
                          >提交</el-button
                        >
                        <el-button @click="showpup2 = false">取消</el-button>
                        <p>
                          注:
                          <span style="color: #ee1515">*</span>为必填项
                        </p>
                      </div>
                    </el-form>
                  </div>
                </div>
              </template>

              <!--弹窗 end-->
              <span class="del_btn" @click="deleteUser">删除</span>
            </dd>
          </dl>
          <div class="project_tables">
            <table width="100%" border="0" cellspacing="0" cellpadding="0">
              <tr>
                <td style="width: 3%">
                  <el-checkbox
                    v-model="checkAll"
                    @change="handleCheckAllChange"
                  ></el-checkbox>
                </td>
                <td style="width: 3%">全选</td>
                <td style="width: 5%">姓名</td>
                <td style="width: 5%">职务</td>
                <td style="width: 5%">职级</td>
                <td style="width: 6%">所在机构</td>
                <td style="width: 7%">所在部门</td>
                <td style="width: 7%">直属领导</td>
                <td style="width: 7%">手机电话</td>
                <td style="width: 5%">年龄</td>
                <td style="width: 5%">性别</td>
                <td style="width: 7%">邮箱</td>
                <td style="width: 5%">用户名</td>
                <td style="width: 7%">创建时间</td>
                <td style="width: 15%">操作</td>
                <td style="width: 5%">状态</td>
              </tr>
              <tr v-for="i in list2" :key="i.id">
                <td>
                  <el-checkbox-group
                    v-model="checkedCities"
                    @change="handleCheckedCitiesChange"
                  >
                    <el-checkbox :label="i.id"></el-checkbox>
                  </el-checkbox-group>
                </td>
                <td></td>
                <td>{{ i.real_name }}</td>
                <td>{{ i.position }}</td>
                <td>{{ i.level }}</td>
                <td>{{ i.unit }}</td>
                <td>{{ i.section }}</td>
                <td>{{ i.leader }}</td>
                <td>{{ i.mobile }}</td>
                <td>{{ i.age }}</td>
                <td>{{ i.sex }}</td>
                <td>{{ i.email }}</td>
                <td>{{ i.name }}</td>
                <td>{{ i.create_time.substr(0, 10) }}</td>
                <td>
                  <el-popover
                    v-if="i.status != '通过'"
                    placement="bottom"
                    width="320"
                    trigger="click"
                    :ref="'poptg' + i.id"
                  >
                    <el-form
                      :model="numberValidateForm"
                      ref="numberValidateForm"
                      class="demo-ruleForm"
                    >
                      <span class="shenqing">是否通过此申请?</span>
                      <div style="text-align: center">
                        <el-button @click="closetg(i.id)">取消</el-button>
                        <el-button
                          class="el-button--primary"
                          @click="updateAccount(i.id, 1)"
                          >确定</el-button
                        >
                      </div>
                    </el-form>
                    <el-button
                      style="color: rgb(123, 155, 238)"
                      slot="reference"
                      >通过</el-button
                    >
                  </el-popover>
                  <span
                    v-if="i.status != '在审'"
                    class="huangzi_btn"
                    @click="updateAccount(i.id, 2)"
                    >在审</span
                  >

                  <el-popover
                    v-if="i.status != '驳回'"
                    placement="bottom"
                    width="320"
                    trigger="click"
                    :ref="'popqbh' + i.id"
                  >
                    <el-form
                      :model="numberValidateForm"
                      ref="numberValidateForm"
                      class="demo-ruleForm"
                    >
                      <span class="shenqing">是否驳回此申请?</span>
                      <div style="text-align: center">
                        <el-button @click="closebh(i.id)">取消</el-button>
                        <el-button
                          class="el-button--primary"
                          @click="updateAccount(i.id, 3)"
                          >确定</el-button
                        >
                      </div>
                    </el-form>
                    <el-button
                      style="color: rgb(239, 109, 109)"
                      slot="reference"
                      >驳回</el-button
                    >
                  </el-popover>
                  <el-popover
                    placement="bottom"
                    width="500"
                    trigger="click"
                    @show="userInsideInfo(i.id)"
                    :ref="'popbj' + i.id"
                  >
                    <div v-if="showorg" style="height: 600px; overflow: auto">
                      <el-form
                        :model="numberValidateForm"
                        ref="numberValidateForm"
                        label-width="100px"
                        class="demo-ruleForm"
                      >
                        <el-form-item
                          label="姓名 :"
                          prop="real_name"
                          :rules="[{ required: true, message: '姓名不能为空' }]"
                        >
                          <el-input
                            type="real_name"
                            v-model="numberValidateForm.real_name"
                            autocomplete="off"
                          ></el-input>
                        </el-form-item>
                        <el-form-item
                          label="职务 :"
                          prop="position"
                          :rules="[{ required: true, message: '职务不能为空' }]"
                        >
                          <el-input
                            type="position"
                            v-model="numberValidateForm.position"
                            autocomplete="off"
                          ></el-input>
                        </el-form-item>
                        <el-form-item
                          label="职级1 :"
                          prop="level"
                          :rules="[{ required: true, message: '职级不能为空' }]"
                        >
                          <el-select
                            v-model="numberValidateForm.level"
                            placeholder="请选择"
                            @change="labelchange($event, listzj, 4)"
                          >
                            <el-option
                              v-for="(item, index) in listzj"
                              :key="index"
                              :label="item.label"
                              :value="item.value"
                            ></el-option>
                          </el-select>
                        </el-form-item>
                        <el-form-item label="所在机构 :" prop="unit">
                          <el-select
                            v-model="numberValidateForm.unit"
                            placeholder="请选择"
                            @change="labelchange($event, goverment, 2)"
                          >
                            <el-option
                              v-for="(item, index) in goverment"
                              :key="index"
                              :label="item.label"
                              :value="item.value"
                            ></el-option>
                          </el-select>
                        </el-form-item>
                        <el-form-item label="所在部门 :" prop="section">
                          <el-select
                            v-model="numberValidateForm.section"
                            placeholder="请选择"
                            @change="labelchange($event, invest, 3)"
                          >
                            <el-option
                              v-for="(item, index) in invest"
                              :key="index"
                              :label="item.label"
                              :value="item.value"
                            ></el-option>
                          </el-select>
                        </el-form-item>
                        <el-form-item
                          label="直系领导 :"
                          prop="leader"
                          :rules="[
                            { required: true, message: '直系领导不能为空' },
                          ]"
                        >
                          <el-input
                            type="name"
                            v-model="numberValidateForm.leader"
                            autocomplete="off"
                          ></el-input>
                        </el-form-item>
                        <el-form-item
                          label="联系电话 :"
                          prop="mobile"
                          :rules="[
                            { required: true, message: '联系电话不能为空' },
                            { type: '', message: '' },
                          ]"
                        >
                          <el-input
                            type="mobile"
                            v-model.number="numberValidateForm.mobile"
                            autocomplete="off"
                          ></el-input>
                        </el-form-item>
                        <el-form-item
                          label="性别 :"
                          prop="sex"
                          :rules="[{ required: true, message: '性别不能为空' }]"
                        >
                          <el-select
                            v-model="numberValidateForm.sex"
                            placeholder="请选择"
                          >
                            <el-option label="男" value="1"></el-option>
                            <el-option label="女" value="2"></el-option>
                          </el-select>
                        </el-form-item>
                        <el-form-item
                          label="年龄 :"
                          prop="age"
                          :rules="[
                            { required: true, message: '年龄不能为空' },
                            { type: 'number', message: '年龄必须为数字值' },
                          ]"
                        >
                          <el-input
                            type="age"
                            v-model.number="numberValidateForm.age"
                            autocomplete="off"
                          ></el-input>
                        </el-form-item>
                        <el-form-item label="邮箱 :" prop="email">
                          <el-input
                            type="email"
                            v-model="numberValidateForm.email"
                            autocomplete="off"
                          ></el-input>
                        </el-form-item>
                        <el-form-item
                          label="用户名 :"
                          prop="name"
                          :rules="[
                            { required: true, message: '用户名不能为空' },
                          ]"
                        >
                          <el-input
                            type="name"
                            v-model="numberValidateForm.name"
                            autocomplete="off"
                          ></el-input>
                        </el-form-item>
                        <el-form-item label="密码 :" prop="password">
                          <el-input
                            type="password"
                            v-model="numberValidateForm.password"
                            autocomplete="off"
                          ></el-input>
                        </el-form-item>

                        <div class="btn">
                          <el-button type="primary" @click="updateUser(i.id)"
                            >提交</el-button
                          >
                          <el-button @click="close(i.id)">取消</el-button>
                          <p>
                            注:
                            <span style="color: #ee1515">*</span>为必填项
                          </p>
                        </div>
                      </el-form>
                    </div>

                    <el-button class="lvzi_btn" slot="reference"
                      >编辑</el-button
                    >
                  </el-popover>
                </td>
                <td ref="stata">{{ i.status }}</td>
              </tr>
            </table>
          </div>
          <div class="block">
            <el-pagination
              @current-change="handleCurrentChangejunei"
              :current-page.sync="currentPage1"
              :page-size="10"
              layout="total, prev, pager, next"
              :total="Number(total)"
            ></el-pagination>
          </div>
        </div>
      </template>
      <!--            外部访问管理-->
      <template v-if="!kaiguan">
        <div class="project_main">
          <div class="project_tables">
            <table width="100%" border="0" cellspacing="0" cellpadding="0">
              <tr>
                <td style="width: 5%">姓名</td>
                <td style="width: 5%">用户名</td>
                <td style="width: 6%">手机号</td>
                <td style="width: 7%">年龄</td>
                <td style="width: 7%">性别</td>
                <td style="width: 7%">注册时间</td>
                <td style="width: 5%">邮箱</td>
                <td style="width: 5%">状态</td>
              </tr>
              <tr v-for="(i, index) in listyonghu" :key="i.id">
                <td>{{ i.real_name }}</td>
                <td>{{ i.name }}</td>
                <td>{{ i.mobile }}</td>
                <td>{{ i.age }}</td>
                <td>{{ i.sex }}</td>
                <td>{{ i.create_time.substr(0, 10) }}</td>
                <td>{{ i.email }}</td>
                <td>
                  <el-switch
                    @change="statusxg(i.status, index, i.id)"
                    v-model="i.status == 1 ? true : false"
                    active-color="#fe6a00"
                  ></el-switch>
                  <span>{{ i.status == 1 ? "启动" : "禁用" }}</span>
                </td>
              </tr>
            </table>
          </div>
          <div class="block">
            <el-pagination
              @current-change="handleCurrentChange"
              :current-page.sync="currentPage1"
              :page-size="10"
              layout="total, prev, pager, next"
              :total="Number(total2)"
            ></el-pagination>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import {
  fields,
  fieldt,
  sousuo,
  listgetRole,
  addUser,
  updateUser,
  userInsideInfo,
  updateAccount,
  deleteUser,
  listOfExternal,
  liststatus,
} from "@/api/index";

export default {
  data() {
    return {
      currentPage1: 1,
      keyword: "",
      kaiguan: true,
      showpup2: false,
      listyonghu: "",
      list2: "",
      total: "",
      total2: "",
      invest: "",
      page: 1,
      pagejunei: 1,
      valueaptitude: "",
      valueaptitude2: "",
      valuetype: "通过",
      industry: "",
      statu: 1, //状态
      goverment: "",
      organization: "",
      checkAll: false,
      checkAllarr: [],
      stageid: "",
      showorg: false,
      itemid: "",
      listzj: "", //职级
      rank: "", //职级
      yonghuid: "",
      idx: [],
      numberValidateForm: {
        age: "",
        sex: "",
        email: "",
        password: "",
        name: "", //用户名
        position: "", //职务
        real_name: "", //姓名
        level: "", //职级
        unit: "", //所在机构
        section: "", //所在部门
        leader: "", //直属领导
        mobile: "", //手机电话
        create_time: "",
      },
      formInline: {
        user: "",
        region: "",
      },
      checkedCities: [],
    };
  },
  created() {
    this.chaxun();
    this.fields();
    this.sousuo();
    this.listgetRole();
  },
  methods: {
    // 全选
    handleCheckAllChange(val) {
      var checkeall = [];
      for (var i = 0; i < this.list2.length; i++) {
        checkeall.push(this.list2[i].id);
      }
      this.checkedCities = val ? checkeall : [];
    },
    // 取消全选
    handleCheckedCitiesChange(value) {
      this.idx = value;
      let checkedCount = value.length;
      this.checkAll = checkedCount === this.list2.length;
    },

    //编辑状态
    async updateAccount(a, b) {
      const res = await updateAccount({
        id: a,
        status: b,
      });
      if (res.code == 0) {
        this.$message({
          message: res.msg,
          type: "success",
        });
        if (b == 1) {
          let refName2 = "poptg" + a;
          this.$refs[refName2][0].doClose();
        } else if (b == 3) {
          let refName2 = "popqbh" + a;
          this.$refs[refName2][0].doClose();
        }
      } else {
        this.$message({
          message: res.msg,
          type: "error",
        });
      }
      this.sousuo();
    },
    closetg(a) {
      let refName2 = "poptg" + a;
      this.$refs[refName2][0].doClose();
    },
    closebh(a) {
      let refName2 = "popbh" + a;
      this.$refs[refName2][0].doClose();
    },
    // 删除
    async deleteUser() {
      const res = await deleteUser({ id: this.idx });
      if (res.code == 0) {
        this.$message({
          message: res.msg,
          type: "success",
        });
        this.sousuo();
        this.idx = [];
      } else {
        this.$message({
          message: res.msg,
          type: "error",
        });
      }
      this.sousuo();
    }, // 密码验证
    allSame(value) {
      let data = value.split("");
      let chartCode = []; // 存放ASCII码
      let same = true;
      for (let i in data) {
        chartCode.push(value.charCodeAt(i));
        if (chartCode.length === data.length) {
          for (let i = 1; i <= chartCode.length; i++) {
            if (Math.abs(Number(chartCode[i]) - Number(chartCode[i - 1])) > 1) {
              same = false;
              break;
            }
          }
        }
      }
      return same;
    },
    // 密码验证
    passMessage(s) {
      var i = /[0-9]+/;
      var str = /[A-Za-z]/;
      var spec = /[,.<>{}~!@#$%^&*]/;
      if (!str.test(s)) {
        return "密码中必须包含字母";
      }
      if (!i.test(s)) {
        return "密码中必须包含数字";
      }
      if (!spec.test(s)) {
        return "密码中必须包含特殊字符。例如：!@#$%^&* 其中一个或多个";
      }
    },

    //编辑
    async updateUser(id) {
      if (
        this.numberValidateForm.sex == 1 ||
        this.numberValidateForm.sex == 2
      ) {
      } else {
        if (this.numberValidateForm.sex == "男") {
          this.numberValidateForm.sex = 1;
        } else {
          this.numberValidateForm.sex = 2;
        }
      }
      // console.log(this.numberValidateForm.sex)
      // this.rank = this.numberValidateForm.level
      if (this.rank == "") {
        this.$message({
          message: "请选择职级",
          type: "error",
        });
        return false;
      }
      if (this.numberValidateForm.age == "") {
        this.$message({
          message: "请填写年龄",
          type: "error",
        });
        return false;
      }
      let refName2 = "popbj" + id;
      this.$refs[refName2][0].doClose();
      const res = await updateUser({
        id: this.yonghuid,
        real_name: this.numberValidateForm.real_name,
        position: this.numberValidateForm.position,
        level: this.rank,
        unit: this.organization,
        section: this.stageid,
        leader: this.numberValidateForm.leader,
        mobile: this.numberValidateForm.mobile,
        sex: this.numberValidateForm.sex,
        age: this.numberValidateForm.age,
        email: this.numberValidateForm.email,
        name: this.numberValidateForm.name,
        password: this.numberValidateForm.password,
      });
      if (res.code == 0) {
        this.$message({
          message: res.msg,
          type: "success",
        });
        this.sousuo();
      } else {
        this.$message({
          message: res.msg,
          type: "error",
        });
      }
    },
    // 取消
    close(id) {
      let refName2 = "popbj" + id;
      this.$refs[refName2][0].doClose();
    },
    //增加
    newuser() {
      this.showpup2 = true;
      this.numberValidateForm.real_name = "";
      this.numberValidateForm.position = "";
      this.rank = "";
      this.organization = "";
      this.stageid = "";
      this.numberValidateForm.level = "";
      this.numberValidateForm.mobile = "";
      this.numberValidateForm.sex = "";
      this.numberValidateForm.age = "";
      this.numberValidateForm.email = "";
      this.numberValidateForm.name = "";
      this.numberValidateForm.password = "";
    },
    async addUser() {
      let msg = this.passMessage(this.numberValidateForm.password);
      if (msg) {
        this.$message({
          message: msg,
          type: "error",
        });
        return false;
      }
      if (this.numberValidateForm.password.length < 8) {
        this.$message({
          message: "密码长度应8位以上",
          type: "error",
        });
        return false;
      }
      if (this.allSame(this.numberValidateForm.password)) {
        this.$message({
          message: "不能使用连续性字母，数字作为密码",
          type: "error",
        });
        return false;
      }
      const res = await addUser({
        real_name: this.numberValidateForm.real_name,
        position: this.numberValidateForm.position,
        level: this.rank,
        unit: this.organization,
        section: this.stageid,
        leader: this.numberValidateForm.level,
        mobile: this.numberValidateForm.mobile,
        sex: this.numberValidateForm.sex,
        age: this.numberValidateForm.age,
        email: this.numberValidateForm.email,
        name: this.numberValidateForm.name,
        password: this.numberValidateForm.password,
      });
      this.sousuo();
      if (res.code == 0) {
        this.$message({
          message: res.msg,
          type: "success",
        });
        this.showpup2 = false;
      } else {
        this.$message({
          message: res.msg,
          type: "error",
        });
      }
    },
    //获取单个用户
    async userInsideInfo(e) {
      this.showorg = true;
      this.yonghuid = e;
      const res = await userInsideInfo({
        id: this.yonghuid,
      });
      this.numberValidateForm.age = res.age;
      this.numberValidateForm.sex = res.sex;
      this.numberValidateForm.email = res.email;
      this.numberValidateForm.password = res.password;
      this.numberValidateForm.name = res.name;
      this.numberValidateForm.position = res.position;
      this.numberValidateForm.real_name = res.real_name;
      this.numberValidateForm.level = res.level;
      this.listzj.map((item) => {
        if (item.label == res.level) {
          this.rank = item.value;
        }
      });
      this.numberValidateForm.unit = res.unit;
      this.numberValidateForm.section = res.section;
      this.numberValidateForm.leader = res.leader;
      this.numberValidateForm.mobile = res.mobile;
    },
    //获取增加职级
    async listgetRole() {
      const res = await listgetRole({});
      this.listzj = res;
    },

    // 内部
    async fields() {
      const res = await fields({});
      this.industry = res.status;
      this.goverment = res.goverment;
    },

    labelchange(e, objx, num) {
      switch (num) {
        case 1:
          this.statu = e;
          break;
        case 2:
          this.organization = e;
          this.fieldt();
          break;
        case 3:
          this.stageid = e;
          break;
        case 4:
          this.rank = e;
          break;
      }
    },

    async fieldt() {
      const res = await fieldt({
        id: this.organization,
      });
      this.invest = res;
    },
    // 局内
    async sousuo(type) {
      if (type == 1) {
        this.pagejunei = 1;
      }
      const res = await sousuo({
        page: this.pagejunei,
        perpage: 10,
        key: this.keyword, //搜索框
        status: this.statu,
        unit: this.valueaptitude,
        section: this.valueaptitude2,
      });
      this.list2 = res.df;
      this.total = res.size;
    },

    // 外部
    async chaxun() {
      const res = await listOfExternal({
        page: this.page, //分页页码
        perpage: 10, //页面大小
        key: this.keyword, //查询条件
      });
      this.listyonghu = res.df;
      this.total2 = res.size;
    },
    async statusxg(a, b, c) {
      if (a == 1) {
        this.listyonghu[b].status = 2;
      } else {
        this.listyonghu[b].status = 1;
      }
      const res = await liststatus({
        id: c,
        status: a,
      });
    },

    kai(a) {
      if (a == 1) {
        this.kaiguan = true;
      }
      if (a == 2) {
        this.kaiguan = false;
      }
    },

    onSubmit() {
      console.log("submit!");
    },
    handleCurrentChange(val) {
      this.page = val;
      this.chaxun();
    },
    handleCurrentChangejunei(val) {
      this.pagejunei = val;
      this.sousuo();
    },
  },
};
</script>

<style lang="less" scoped>
.soso_box {
  padding: 22px 10px 10px 22px;
  margin-bottom: 15px;
  overflow: hidden;
  zoom: 1;

  .zhaosh_tit {
    font-size: 17px;
    text-align: center;
    // font-weight: bold;
    .cheng {
      background: #fe6a00;
      color: #fff;
    }

    span {
      float: left;
      display: block;
      width: 138px;
      height: 38px;
      line-height: 38px;
      background: #eee;
      margin-right: 20px;
      color: #666;
      border-radius: 6px;
      cursor: pointer;
    }

    .sousuo {
      float: right;
      width: 410px;

      li {
        float: left;
        display: block;
        height: 31px;
        float: left;
        font-size: 14px;
        font-weight: 400;
        text-align: left;
        color: #333333;
        margin: 1% 0;
        width: 300px;

        /deep/ .el-select {
          .el-input {
            width: 100%;
            text-align: left;
          }

          height: 30px;

          input {
            width: 330px;
            height: 30px;
            background: #ffffff;
            border: 1px solid #dddddd;
          }

          /deep/ .el-input__icon {
            line-height: 30px !important;
          }
        }
      }

      .seach {
        width: 20%;
        color: #fff;
        background: #fe6a00;
        margin-top: 4px;
      }
    }
  }

  .ipt {
    overflow: hidden;
    zoom: 1;
    text-align: left;
    width: 100%;

    li {
      display: block;
      width: 16.5%;
      height: 31px;
      float: left;
      font-size: 14px;
      font-weight: 400;
      text-align: left;
      color: #333333;
      margin: 1% 0;

      /deep/ .el-select {
        width: 70%;
        height: 30px;

        input {
          width: 100%;
          height: 30px;
          background: #ffffff;
          border: 1px solid #dddddd;
        }

        /deep/ .el-input__icon {
          line-height: 30px !important;
        }
      }

      /deep/ .el-input {
        width: 100%;
        height: 30px;

        input {
          width: 100%;
          height: 30px;
          color: #333;
          background: #ffffff;
          border: 1px solid #dddddd;
        }

        ::-webkit-input-placeholder {
          color: #333;
        }

        // /deep/ .el-icon-circle-close {
        //   background: url("../../assets/merchantsradar/cuo.png") 50% 50%
        //     no-repeat;
        // }

        // .el-icon-circle-close:before {
        //   content: "1";
        // }
      }
    }

    li:nth-child(2) {
      width: 20%;

      /deep/ .el-select {
        width: 60%;
      }
    }

    li:nth-child(3) {
      width: 20%;

      /deep/ .el-select {
        width: 55%;
      }
    }
  }

  .project_main {
    width: 100%;
    padding: 18px 10px 10px 22px;
    min-height: 80%;
    overflow: hidden;
    zoom: 1;

    .project_tit {
      overflow: hidden;
      zoom: 1;
      margin-bottom: 10px;

      dt:before {
        content: " ";
        padding: 0 3px 0 0;
        margin-right: 6px;
        background: #fe6a00;
      }

      dd {
        float: left;

        .el-checkbox {
          margin-right: 15px;
        }

        .xzeng_btn {
          display: inline-block;
          height: 30px;
          line-height: 30px;
          background: #fe6a00;
          border-radius: 4px;
          font-size: 14px;
          text-align: center;
          color: #ffffff;
          margin: 1% 5px;
          padding: 0 20px;
          cursor: pointer;
        }

        .del_btn {
          display: inline-block;
          height: 30px;
          line-height: 30px;
          border: 1px solid #fe6a00;
          border-radius: 4px;
          font-size: 14px;
          text-align: center;
          color: #fe6a00;
          margin: 1% 5px;
          padding: 0 20px;
          cursor: pointer;
        }

        .xiaz_btn {
          display: inline-block;
          height: 30px;
          line-height: 30px;
          border: 1px solid #fe6a00;
          border-radius: 4px;
          font-size: 14px;
          text-align: center;
          color: #fe6a00;
          margin: 1% 5px;
          padding: 0 20px;
          cursor: pointer;
        }
      }
    }

    .project_tables {
      font-size: 14px;
      line-height: 44px;

      tr:nth-child(odd) {
        background: #f3f6fb;

        td {
          border-top: 1px solid #eee;
          border-bottom: 1px solid #eee;
        }
      }

      span {
        margin: 0 2%;
      }

      .chengzi_btn {
        color: #fe6a00;
        cursor: pointer;
      }

      .lvzi_btn {
        color: #2cb54c;
        cursor: pointer;
      }

      .zizi_btn {
        color: #135de6;
        cursor: pointer;
      }

      .redzi_btn {
        color: #e51313;
        cursor: pointer;
      }

      .bluezi_btn {
        color: #0ebbf3;
        cursor: pointer;
      }

      .huangzi_btn {
        color: #e69554;
        cursor: pointer;
      }

      /deep/ .el-button {
        background: none;
        padding: 0;
        border: 0;
      }
    }

    //分页
    /deep/ .el-pagination {
      float: right;
      margin-top: 20px;
    }

    /deep/ .btn-prev {
      border: 1px solid #888;
      padding: 0;
    }

    /deep/ .btn-next {
      border: 1px solid #888;
      padding: 0;
    }

    /deep/ .el-pager li {
      border: 1px solid #888;
      margin: 0 5px;
    }

    /deep/ .el-pager li.active,
    /deep/ .el-pager li:hover {
      border: 1px solid #fe6a00;
      color: #fe6a00;
    }

    //分页end
  }
}

/deep/ .el-button--primary {
  color: #fff;
  background-color: #fe6a00;
  border-color: #fe6a00;
}

/deep/ .el-popover {
  height: 600px;
  overflow: auto;
}

/deep/ .el-form-item__label {
  text-align: left;
  text-indent: 1em;
}

/deep/ .el-input {
  width: 80%;
}

/deep/ .el-form-item__content {
  text-align: left;
}

.shenqing {
  display: block;
  width: 100%;
  height: 70px;
  text-align: center;
  line-height: 70px;
}

/*弹窗*/
.dabox {
  /*display:none;*/
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2000;

  .neibox {
    width: 40%;
    height: 70%;
    background: #fff;
    margin-top: 10%;
    margin-left: 30%;
    overflow: auto;
    padding-bottom: 20px;

    /deep/ .el-input {
      width: 80%;
    }

    /deep/ .el-form-item__content {
      text-align: left;
    }

    p:nth-child(1) {
      display: block;
      width: 100%;
      height: 54px;
      background: #fff3eb;
      margin-bottom: 17px;
      text-align: left;
      text-indent: 1em;
      line-height: 54px;
      font-size: 18px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #fe6a00;
    }
  }
}

.btn {
  text-align: center;
  margin-top: 10px;

  p {
    margin-top: 20px;
  }
}
/deep/.el-checkbox__label {
  display: none;
}
/deep/.el-input__icon {
  line-height: 27px;
}
</style>
